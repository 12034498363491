declare global {
  interface Window {
    plugins?: any;
  }
}

export interface CordovaPaypalMagnesPluginInterface {
  /**
   * Get the RiskCorrelationId
   * In case of failure i return 'NULL'
   */
  getRCID(): Promise<string | null>;
}

interface PaypalMagnesPlugin {
  /**
   * I do FraudnetInitialization and return the RiskCorrelationId.
   */
  initAndGetRcid: () => string;
}

export class CordovaPaypalMagnesPlugin
  implements CordovaPaypalMagnesPluginInterface {
  constructor(private readonly paypalMagnesPlugin: PaypalMagnesPlugin) {}

  async getRCID(): Promise<string | null> {
    const rcid = await this.paypalMagnesPlugin.initAndGetRcid();
    return Promise.resolve(rcid);
  }
}

export class CordovaPaypalMagnesNoopPlugin
  implements CordovaPaypalMagnesPluginInterface {
  getRCID(): Promise<string | null> {
    return Promise.resolve(null);
  }
}
